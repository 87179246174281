import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Box, Hidden, useMediaQuery } from '@material-ui/core'
import font from '@helpers/font'
import {
  H1,
  H6,
  BackgroundImage,
  MarkdownHandler,
  Img,
  ContentContainer,
} from '@system'
import classNames from 'classnames'
import ACC_THEME from '@themes'
import isInBrowser from '@helpers/is-in-browser'
import get from 'lodash/get'
import vp9 from '@images/hero-out-vp9-transparent.webm'
import mp4 from '@images/hero-out-mp4-transparent.mp4'
// import heroPosterPng from '@images/hero-video-poster.png'
import heroPosterWebp from '@images/hero-video-poster.webp'
import foregroundImageLocalPng from '@images/hero-foreground-mobile.png'
import foregroundImageLocalWebp from '@images/hero-foreground-mobile.webp'

const { makeResponsiveFontSizes, pxToRem, adjustedStartValue } = font

const useStyles = makeStyles((theme) => {
  return {
    title: {
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
        marginBottom: 0,
      },
    },
    smallerTitle: {
      // legacy
      fontSize: pxToRem(adjustedStartValue(62)),
      fontWeight: theme.typography.fontWeightLight,
      fontFamily: theme.typography.h1.fontFamily,
      letterSpacing: '-0.18px',
      lineHeight: 1.2,
      ...makeResponsiveFontSizes(59, 21),
    },
    titleMedium: {
      ...makeResponsiveFontSizes(49, 21),
    },
    titleSmall: {
      ...makeResponsiveFontSizes(36),
    },
    titleBold: {
      fontWeight: 800,
    },
    markdownDescription: {
      color: theme.palette.common.white,
      display: 'block',
      letterSpacing: '-0.18px',
      lineHeight: 1.5,
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        padding: '10px 0px',
      },
    },
    smallerDescription: {
      ...makeResponsiveFontSizes(21, 15),
    },
    imageBackground: ({
      blok: { appLinks, bgImageAlign, bgImageSize, moduleHeight },
      isHomepage,
      mobileBgPresent,
    }) => ({
      width: '100%',
      minHeight:
        (appLinks && appLinks.length > 0) || mobileBgPresent
          ? '550px'
          : moduleHeight
          ? '20vh'
          : '500px',
      backgroundColor: theme.palette.primary.main,
      backgroundPosition: bgImageAlign ? `${bgImageAlign} !important` : null,
      '&:after': {
        backgroundPosition: isHomepage
          ? 'bottom right !important'
          : bgImageAlign
          ? `${bgImageAlign} !important`
          : 'top right !important',
        backgroundSize: isHomepage
          ? 'contain !important'
          : bgImageSize
          ? `${bgImageSize} !important`
          : null,
      },
      '&:before': {
        backgroundPosition: isHomepage
          ? 'bottom right !important'
          : bgImageAlign
          ? `${bgImageAlign} !important`
          : 'top right !important',
        backgroundSize: isHomepage
          ? 'contain !important'
          : bgImageSize
          ? `${bgImageSize} !important`
          : null,
      },
    }),
    backgroundGradient: {
      position: 'relative',
      background: theme.palette.gradient.background,
    },
    video: ({ blok: { moduleHeight } }) => ({
      '& >video': {
        objectFit: 'cover',
        width: '100%',
        minHeight: moduleHeight ? '40vh' : '500px',
        height: moduleHeight || '40vh',
        maxHeight: moduleHeight ? '100vh' : '900px',
      },
      width: '100%',
      height: moduleHeight || '40vh',
      minHeight: moduleHeight ? '40vh' : '500px',
      maxHeight: '900px',
      position: 'absolute',
      overflow: 'hidden',
      backgroundColor: theme.palette.primary.main,
    }),
    contentBox: ({
      mobileBgPresent,
      blok: { appLinks, moduleHeight, variant, textProportion },
      isHomepage,
    }) => ({
      position: 'relative',
      minHeight:
        (appLinks && appLinks.length > 0) || mobileBgPresent
          ? '550px'
          : moduleHeight
          ? '40vh'
          : '500px',
      height: '100%',
      width: `${textProportion}%`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      zIndex: 10,
      color: theme.palette.common.white,
      margin: variant === 'centered-layout' ? '0 auto' : '0',
      textAlign: variant === 'centered-layout' ? 'center' : 'left',
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: mobileBgPresent && !isHomepage ? 'flex-end' : 'center',
      },
    }),
    ctaColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
    ctaContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: '24px',
      justifyContent: (props) =>
        props.blok.variant === 'centered-layout' ? 'center' : 'flex-start',
      [theme.breakpoints.up('md')]: {
        paddingBottom: '2rem',
      },
    },
    button: {
      margin: '1rem 0 0.8rem',
      display: 'block',
    },
    secondaryCTAContainer: {
      margin: '1rem 0 0.8rem',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    secondaryCTAStyle: {
      textDecoration: 'none',
    },
    breadcrumblinkContainer: {
      paddingBottom: '1.5rem',
    },
    breadcrumblinkStyle: {
      textTransform: 'uppercase',
      borderBottom: `1px dashed ${theme.palette.common.white}`,
      textDecoration: 'none',
    },
    link: {
      alignSelf: 'center',
      margin: '0px 16px',
      '& a': {
        borderColor: theme.palette.common.white,
        '&:hover': {
          borderColor: theme.palette.common.white,
        },
      },
    },
    alert: {
      paddingBottom: '0.5rem',
      fontWeight: 700,
      color: (props) =>
        props.blok.eyebrowCustomColor?.color || theme.palette.text.plant,
      fontSize: theme.typography.h6.fontSize,
    },
    topLogo: {
      alignSelf: (props) =>
        props.blok.variant === 'centered-layout' ? 'center' : 'flex-start',
      maxHeight: (props) => props.blok.topLogoMaxHeight || '120px',
      maxWidth: '90vw',
      padding: '30px 0px',
    },
    backgroundOverlay: {
      '&:before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: '1',
        background: (props) =>
          `linear-gradient(left, rgba(0,0,0,.5)0%, rgba(0,0,0,.5) ${props.blok.textProportion}%, rgba(0,0,0,0) 100%)`,
      },
    },
    outerContainer: ({ blok: { moduleHeight } }) => ({
      width: '100%',
      minHeight: moduleHeight ? '40vh' : '500px',
      height: moduleHeight || '40vh',
      maxHeight: '900px',
      position: 'relative',
    }),
    contentHeight: ({ isHomepage }) => ({
      height: '100%',
      paddingBottom: isHomepage ? '4rem' : null,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: isHomepage ? '2.5rem' : null,
        paddingTop: isHomepage ? '1.5rem' : null,
      },
    }),
    infoTextMarkdown: {
      marginTop: '15px',
      color: theme.palette.common.white,
    },
    socialLinks: ({ blok: { infoText } }) => ({
      marginTop: !infoText && '15px',
      '& a': {
        color: theme.palette.common.white,
        lineHeight: '38px',
        marginRight: '30px',
        display: 'inline-block',
      },
    }),
    imageContainer: ({ blok: { rightImageAlign, textProportion } }) => ({
      width: `calc(${96 - textProportion}% + ${
        rightImageAlign && rightImageAlign.indexOf('right') > -1 ? 25 : 0
      }px)`,
      position: 'absolute',
      top: 0,
      bottom: 0,
      objectFit: 'contain',
      aspectRatio: '1280 / 960',
      right:
        rightImageAlign && rightImageAlign.indexOf('right') > -1 ? 0 : '25px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent:
        rightImageAlign && rightImageAlign.indexOf('top') > -1
          ? 'flex-start'
          : rightImageAlign && rightImageAlign.indexOf('bottom') > -1
          ? 'flex-end'
          : 'center',
      height: '100%',
      '& img': {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        position: 'static',
        width: '100%',
      },
    }),
    foregroundImageStyle: {
      maxHeight: '450px',
      objectFit: 'contain',
      objectPosition: 'left',
    },
  }
})

const ForegroundContent = (props) => {
  const {
    alertText,
    alertTextIsH1,
    appLinks,
    boldTitle,
    breadcrumbLink,
    button,
    description,
    foregroundImage,
    hasBackgroundOverlay,
    infoText,
    secondaryCTA,
    smallerDescription,
    smallerTitle,
    title,
    titleSize,
    topLogo,
    topLogoH1Text,
    variant,
    shouldRenderH1,
  } = props.blok

  const {
    alert,
    backgroundOverlay,
    breadcrumblinkContainer,
    breadcrumbLinkStyle,
    button: buttonStyle,
    contentBox,
    contentHeight,
    ctaColumn,
    ctaContainer,
    imageContainer,
    infoTextMarkdown,
    markdownDescription,
    secondaryCTAContainer,
    secondaryCTAStyle,
    smallerDescription: smallerDescriptionStyle,
    smallerTitle: smallerTitleStyle,
    socialLinks,
    title: titleStyle,
    titleBold,
    titleMedium,
    titleSmall,
    topLogo: topLogoStyle,
    foregroundImageStyle,
  } = props.classes

  return (
    <ContentContainer
      className={classNames(contentHeight, {
        [backgroundOverlay]: hasBackgroundOverlay,
      })}
    >
      <Box className={contentBox}>
        {!!breadcrumbLink && !!breadcrumbLink.length > 0 && (
          <Box className={breadcrumblinkContainer}>
            {renderBloks(breadcrumbLink, {
              className: breadcrumbLinkStyle,
            })}
          </Box>
        )}
        {!!topLogo && (
          <>
            <img className={topLogoStyle} src={topLogo} />
            {shouldRenderH1 && !alertTextIsH1 && topLogoH1Text && (
              <h1
                style={{
                  color: 'transparent',
                  height: '1px',
                  margin: '0',
                  width: '1px',
                }}
              >
                {topLogoH1Text}
              </h1>
            )}
          </>
        )}
        {secondaryCTA?.length > 0 && variant === 'centered-layout' && (
          <Box mb={2} mt={4}>
            {renderBloks(secondaryCTA, {
              className: secondaryCTAStyle,
            })}
          </Box>
        )}
        {!!alertText && (
          <H6
            className={alert}
            component={
              shouldRenderH1 && alertTextIsH1 && !topLogoH1Text ? 'h1' : 'h6'
            }
          >
            {alertText}
          </H6>
        )}
        <H1
          component={
            shouldRenderH1 && !alertTextIsH1 && !topLogoH1Text ? 'h1' : 'h2'
          }
          className={classNames(titleStyle, {
            [smallerTitleStyle]: smallerTitle,
            [titleMedium]: titleSize === 'medium',
            [titleSmall]: titleSize === 'small',
            [titleBold]: boldTitle,
          })}
        >
          {title}
        </H1>
        <Box className={ctaColumn}>
          <MarkdownHandler
            isDark
            className={classNames(markdownDescription, {
              [smallerDescriptionStyle]: smallerDescription,
            })}
          >
            {description}
          </MarkdownHandler>
        </Box>
        <MarkdownHandler className={infoTextMarkdown}>
          {infoText}
        </MarkdownHandler>
        {(button?.length > 0 || secondaryCTA?.length > 0) && (
          <Box className={ctaContainer}>
            {button?.length > 0 && (
              <Typography className={buttonStyle} component="div">
                {renderBloks(button, {
                  shouldAutoLoadVideo: props.shouldAutoLoadVideo,
                })}
              </Typography>
            )}
            {secondaryCTA?.length > 0 && variant !== 'centered-layout' && (
              <Box mx={3} className={secondaryCTAContainer}>
                {renderBloks(secondaryCTA, {
                  className: secondaryCTAStyle,
                })}
              </Box>
            )}
          </Box>
        )}
        {props.isHomepage && props.handheldDevice && (
          <Box className={imageContainer}>
            <picture>
              <source srcSet={foregroundImageLocalWebp} type="image/webp" />
              <source srcSet={foregroundImageLocalPng} type="image/png" />
              <img fetchPriority="high" src={foregroundImageLocalPng} />
            </picture>
          </Box>
        )}
        {appLinks && appLinks.length > 0 && (
          <Box className={socialLinks}>{renderBloks(appLinks)}</Box>
        )}
      </Box>
      {props.isHomepage && !props.handheldDevice ? (
        <video
          autoPlay={true}
          playsInline={true}
          muted={true}
          loop={true}
          preload="metadata"
          poster={heroPosterWebp}
          className={imageContainer}
        >
          Sorry your browser does not support HTML5 video
          <source type="video/mp4; codecs=hvc1" src={mp4} data-src={mp4} />
          <source type="video/webm; codecs=vp9" src={vp9} data-src={vp9} />
        </video>
      ) : !!foregroundImage && !props.isHomepage ? (
        <Hidden smDown>
          <Box className={imageContainer}>
            <img className={foregroundImageStyle} src={foregroundImage} />
          </Box>
        </Hidden>
      ) : null}
    </ContentContainer>
  )
}

const Background = ({
  classes,
  video,
  image,
  handheldDevice,
  isHomepage,
  isMobile,
  bgImageAlign,
  bgImageSize,
  ...props
}) => {
  return (
    <>
      {isHomepage && isMobile ? (
        <Box
          className={classNames(
            classes.imageBackground,
            classes.backgroundGradient
          )}
        >
          {props.children}
        </Box>
      ) : video && !isMobile ? (
        <Box className={classes.outerContainer}>
          <Box className={classes.video}>
            <Hidden smDown>
              {isInBrowser && (
                <video
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  preload="none"
                  poster={image}
                >
                  Sorry your browser does not support HTML5 video
                  <source type="video/mp4" src={video} />
                </video>
              )}
            </Hidden>
          </Box>
          {props.children}
        </Box>
      ) : image ? (
        <BackgroundImage className={classes.imageBackground} image={image}>
          {props.children}
        </BackgroundImage>
      ) : (
        <Box
          className={classNames(
            classes.imageBackground,
            classes.backgroundGradient
          )}
        >
          {props.children}
        </Box>
      )}
    </>
  )
}

const HeroFrame = (props) => {
  const altBgImage = get(props, 'blok.bgImage[0]')
  const locale = process.env.GATSBY_LOCALE || 'us'
  const handheldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const isHomepage =
    locale === 'us' &&
    isInBrowser &&
    (window?.location.pathname === '/' ||
      window?.location.search.indexOf('path=us/homepage') > -1)

  const classes = useStyles({
    ...props,
    mobileBgPresent: altBgImage && altBgImage.mobileImage?.filename,
    isHomepage,
  })

  const video = !!props.blok.video && props.blok.video
  const image =
    altBgImage || (!!props.blok.backgroundImage && props.blok.backgroundImage)

  return (
    <SbEditable content={props.blok}>
      <Background
        classes={classes}
        video={video}
        image={image}
        bgImageAlign={props.blok.bgImageAlign}
        bgImageSize={props.blok.bgImageSize}
      >
        <ForegroundContent
          {...props}
          classes={classes}
          handheldDevice={handheldDevice}
          isHomepage={isHomepage}
        />
      </Background>
    </SbEditable>
  )
}

export default HeroFrame
